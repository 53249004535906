<template>
    <div class="grid gap-4 grid-cols-1" :class="`md:grid-cols-${gridCols}`">
        <template v-for="category in categories" :key="category.slug">
            <template v-if="category.full_slug">
                <SubcategoryCard
                    v-if="parentCategory"
                    :title="category.name"
                    :description="category.abstract"
                    :background-image="category.cover_responsive_images"
                    :show-cta="true"
                    :url="route('categories.show', {categories: category.full_slug})"
                    class="max-w-full">
                </SubcategoryCard>
                <ProductCategoryCard
                    v-else
                    :title="category.name"
                    :description="category.abstract"
                    :background-image="category.cover_responsive_images"
                    :show-cta="true"
                    :url="route('categories.show', {categories: category.full_slug})"
                    class="max-w-full">
                </ProductCategoryCard>
            </template>
        </template>
    </div>
</template>
<script setup>
import {ProductCategoryCard, SubcategoryCard} from '@/Components/Frontend/Typography';

const props = defineProps({
    parentCategory: {
        type: Object,
        default: null,
    },
    categories: {
        type: Array,
        required: true,
    },
});

const gridCols = props.parentCategory ? 1 : props.categories.length !== 4 ? 3 : 2;
</script>
